export interface Row {
  EprvId: number
  ChampionnatId: number
  ChampionnatLibelle: string
  EprvNom: string
  EprvLieu: string
  StructureNom: string
  EprvDateDebut: string
  EprvDateFin: string
  InfoSupVal: string | null
  Mandat: boolean
}

export interface Championnat {
  id: number
  name: string
}

// SELECT
//     `EprEpreuves`.`EprvId`,
//     `EprEpreuves`.`ChampionnatId`,
//     `ChampionnatLibelle`,
//     `EprvNom`,
//     `EprvLieu`,
//     `StructureNom`,
//     `EprvDateDebut`,
//     `EprvDateFin`,
//     `InfoSupVal`,
//     FALSE AS `Mandat`
// FROM
//     `ffta_exalto`.`EprEpreuves`
//     LEFT JOIN `EprChampionnats`
//       ON `EprChampionnats`.`ChampionnatId` = `EprEpreuves`.`ChampionnatId`
//     LEFT JOIN `ffta_exalto`.`Structures`
//       ON `ffta_exalto`.`EprEpreuves`.`StructureId` = `ffta_exalto`.`Structures`.`StructureId`
//     LEFT JOIN `EprInfosSup`
//       ON `EprInfosSup`.`EprvId` = `EprEpreuves`.`EprvId` AND `EprInfosSup`.`InfoSupKey` = 'OBSV'
// WHERE (`EprEpreuves`.`EprvDateDebut` >= '2022-01-01' AND `ChampionnatAffichage` = 'O')
// AND (`StructureCodeRegion` = 'CR06' OR (`StructureType` = 'FED' AND `EprvNom` = 'FITA HORIZON'))
// AND `EprvEtat` = 'A'
// ORDER BY
//     `EprvDateDebut`;

const data: Row[] = [
  {
    EprvId: 2025001,
    ChampionnatId: 8,
    ChampionnatLibelle: "Tir en Campagne",
    EprvDateDebut: "2024-09-01",
    EprvDateFin: "2024-09-01",
    StructureNom: "VAGNEY",
    EprvNom: "TIR EN CAMPAGNE",
    EprvLieu: "VAGNEY",
    InfoSupVal: "12 CONNUES + 12 INCONNUES",
    Mandat: true
  },
  {
    EprvId: 2025002,
    ChampionnatId: 8,
    ChampionnatLibelle: "Tir en Campagne",
    EprvDateDebut: "2024-09-01",
    EprvDateFin: "2024-09-01",
    StructureNom: "CHAUMONT",
    EprvNom: "TIR EN CAMPAGNE",
    EprvLieu: "AGEVILLE",
    InfoSupVal: "12 CONNUES + 12 INCONNUES",
    Mandat: true
  },
  {
    EprvId: 2025003,
    ChampionnatId: 8,
    ChampionnatLibelle: "Tir en Campagne",
    EprvDateDebut: "2024-09-07",
    EprvDateFin: "2024-09-08",
    StructureNom: "SARREGUEMINES",
    EprvNom: "CAMPAGNE DU MOULIN DES FAÏENCIERS ",
    EprvLieu: "SARREGUEMINES ",
    InfoSupVal: "12 CONNUES + 12 INCONNUES Repiqueté",
    Mandat: true
  },
  {
    EprvId: 2025004,
    ChampionnatId: 9,
    ChampionnatLibelle: "Tir Nature",
    EprvDateDebut: "2024-09-08",
    EprvDateFin: "2024-09-08",
    StructureNom: "GORZE",
    EprvNom: "NATURE GORZE - SEPT 2024 - 2 DEPARTS",
    EprvLieu: "GORZE",
    InfoSupVal: "21 CIBLES",
    Mandat: true
  },
  {
    EprvId: 2025005,
    ChampionnatId: 6,
    ChampionnatLibelle: "Tir 3D",
    EprvDateDebut: "2024-09-15",
    EprvDateFin: "2024-09-15",
    StructureNom: "SAINT DIZIER",
    EprvNom: "3D DE SAINT-DIZIER",
    EprvLieu: "SAINT-DIZIER",
    InfoSupVal: "1 X 24 CIBLES",
    Mandat: true
  },
  {
    EprvId: 2025006,
    ChampionnatId: 6,
    ChampionnatLibelle: "Tir 3D",
    EprvDateDebut: "2024-09-29",
    EprvDateFin: "2024-09-29",
    StructureNom: "HAUCOURT-MOULAINE",
    EprvNom: "CONCOURS 3D ",
    EprvLieu: "HAUCOURT MOULAINE",
    InfoSupVal: "1 X 24 CIBLES",
    Mandat: true
  },
  {
    EprvId: 2025007,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-05",
    EprvDateFin: "2024-10-06",
    StructureNom: "RETHEL",
    EprvNom: "CONCOURS SALLE",
    EprvLieu: "RETHEL",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025008,
    ChampionnatId: 9,
    ChampionnatLibelle: "Tir Nature",
    EprvDateDebut: "2024-10-06",
    EprvDateFin: "2024-10-06",
    StructureNom: "ILLKIRCH GRAFFENSTADEN",
    EprvNom: "CONCOURS TIR NATURE SELECTIF - OCTOBRE ROSE/ CHAMPIONNAT DEPARTEMENTAL 67",
    EprvLieu: "ILLKIRCH-GRAFFENSTADEN",
    InfoSupVal: "21 CIBLES",
    Mandat: true
  },
  {
    EprvId: 2025009,
    ChampionnatId: 2,
    ChampionnatLibelle: "Jeunes",
    EprvDateDebut: "2024-10-12",
    EprvDateFin: "2024-10-12",
    StructureNom: "SAINT DIZIER",
    EprvNom: "RENCONTRE JEUNES & DEBUTANTS",
    EprvLieu: "SAINT-DIZIER",
    InfoSupVal: "RENCONTRE JEUNES",
    Mandat: false
  },
  {
    EprvId: 2025010,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-12",
    EprvDateFin: "2024-10-13",
    StructureNom: "SARREBOURG",
    EprvNom: "CONCOURS D'AUTOMNE - 3 DÉPARTS 18M",
    EprvLieu: "SARREBOURG",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025011,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-12",
    EprvDateFin: "2024-10-13",
    StructureNom: "CHARLEVILLE MEZIERES",
    EprvNom: "CHALLENGE D'AUTOMNE",
    EprvLieu: "CHARLEVILLE-MÉZIÈRES",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025012,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-13",
    EprvDateFin: "2024-10-13",
    StructureNom: "SAINT DIZIER",
    EprvNom: "SAINT-DIZIER TIR À 18M",
    EprvLieu: "SAINT-DIZIER",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025013,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2024-10-19",
    EprvDateFin: "2024-10-19",
    StructureNom: "SUIPPES",
    EprvNom: "PARTAGE TES FLECHES",
    EprvLieu: "SUIPPES",
    InfoSupVal: "Partage tes flèches",
    Mandat: false
  },
  {
    EprvId: 2025014,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2024-10-19",
    EprvDateFin: "2024-10-19",
    StructureNom: "MULHOUSE STA",
    EprvNom: "PARTAGE TES FLECHES",
    EprvLieu: "MULHOUSE",
    InfoSupVal: "Partage tes flèches",
    Mandat: false
  },
  {
    EprvId: 2025015,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-19",
    EprvDateFin: "2024-10-20",
    StructureNom: "CD MOSELLE",
    EprvNom: "CONCOURS SALLE CREUTZWALD",
    EprvLieu: "CREUTZWALD",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025016,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-19",
    EprvDateFin: "2024-10-20",
    StructureNom: "ARCHERS BRIENNOIS",
    EprvNom: "TIR SALLE",
    EprvLieu: "BRIENNE LE CHATEAU",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025017,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-19",
    EprvDateFin: "2024-10-20",
    StructureNom: "VERTUS",
    EprvNom: "CONCOURS EN SALLE",
    EprvLieu: "VERTUS",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025018,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-19",
    EprvDateFin: "2024-10-20",
    StructureNom: "CHAUMONT",
    EprvNom: "CHAMPINNAT DÉPARTEMENTAL CD 52 18M",
    EprvLieu: "CHAUMONT",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025019,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-20",
    EprvDateFin: "2024-10-20",
    StructureNom: "WISSEMBOURG",
    EprvNom: "CHALLENGE AUTOMNE 2024",
    EprvLieu: "WISSEMBOURG",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025020,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-26",
    EprvDateFin: "2024-10-27",
    StructureNom: "BOULIGNY",
    EprvNom: "TIR A 18 M",
    EprvLieu: "BOULIGNY",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025021,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-26",
    EprvDateFin: "2024-10-27",
    StructureNom: "FOLSCHVILLER",
    EprvNom: "CONCOURS SELECTIF INDIVIDUEL",
    EprvLieu: "FOLSCHVILLER",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025022,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-26",
    EprvDateFin: "2024-10-27",
    StructureNom: "ROMILLY SUR SEINE",
    EprvNom: "TIR EN SALLE ",
    EprvLieu: "ROMILLY SUR SEINE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025023,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-26",
    EprvDateFin: "2024-10-27",
    StructureNom: "CHALONS EN CHAMPAGNE 1ER",
    EprvNom: "CONCOURS SALLE CHÂLONS",
    EprvLieu: "CHÂLONS EN CHAMPAGNE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025024,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-10-27",
    EprvDateFin: "2024-10-27",
    StructureNom: "BRUNSTATT",
    EprvNom: "CONCOURS SELECTIF ",
    EprvLieu: "BRUNSTATT",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025025,
    ChampionnatId: 9,
    ChampionnatLibelle: "Tir Nature",
    EprvDateDebut: "2024-10-27",
    EprvDateFin: "2024-10-27",
    StructureNom: "NEUWILLER LES SAVERNE",
    EprvNom: "TIR NATURE ",
    EprvLieu: "NEUWILLER-LES-SAVERNE",
    InfoSupVal: "21 CIBLES",
    Mandat: false
  },
  {
    EprvId: 2025026,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-02",
    EprvDateFin: "2024-11-03",
    StructureNom: "SEICHAMPS",
    EprvNom: "CHALLENGE D'AUTOMNE 2024",
    EprvLieu: "SEICHAMPS",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025027,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-02",
    EprvDateFin: "2024-11-03",
    StructureNom: "HETTANGE GRANDE",
    EprvNom: "CONCOURS SELECTIF VILLE HETTANGE GRANDE",
    EprvLieu: "HETTANGE GRANDE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025028,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-02",
    EprvDateFin: "2024-11-03",
    StructureNom: "VENDEUVRE SUR BARSE",
    EprvNom: "TIR EN SALLE LUSIGNY",
    EprvLieu: "LUSIGNY-SUR-BARSE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025029,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2024-11-03",
    EprvDateFin: "2024-11-03",
    StructureNom: "OBERNAI",
    EprvNom: "CONCOURS NOVEMBRE",
    EprvLieu: "OBERNAI",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025030,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-03",
    EprvDateFin: "2024-11-03",
    StructureNom: "OBERNAI",
    EprvNom: "CONCOURS NOVEMBRE",
    EprvLieu: "OBERNAI",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025031,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-09",
    EprvDateFin: "2024-11-11",
    StructureNom: "NANCY",
    EprvNom: "CONCOURS SALLE NANCY",
    EprvLieu: "NANCY",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025032,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-09",
    EprvDateFin: "2024-11-11",
    StructureNom: "CHAOURCE",
    EprvNom: "CONCOURS SALLE CHAOURCE 2024",
    EprvLieu: "CHAOURCE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025033,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-09",
    EprvDateFin: "2024-11-10",
    StructureNom: "RETHEL",
    EprvNom: "TIR SALLE",
    EprvLieu: "RETHEL",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025034,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-09",
    EprvDateFin: "2024-11-10",
    StructureNom: "MAREUIL SUR AY",
    EprvNom: "2X 18M EN SALLE",
    EprvLieu: "AY",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025035,
    ChampionnatId: 6,
    ChampionnatLibelle: "Tir 3D",
    EprvDateDebut: "2024-11-10",
    EprvDateFin: "2024-11-10",
    StructureNom: "EPINAL 1ER",
    EprvNom: "TIR 3 D  - SPINAPARC EPINAL",
    EprvLieu: "EPINAL",
    InfoSupVal: "1 X 24 CIBLES",
    Mandat: false
  },
  {
    EprvId: 2025036,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-10",
    EprvDateFin: "2024-11-10",
    StructureNom: "MULHOUSE STA",
    EprvNom: "TIR EN SALLE 18M",
    EprvLieu: "MULHOUSE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025037,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2024-11-16",
    EprvDateFin: "2024-11-17",
    StructureNom: "TROYES",
    EprvNom: "DÉBUTANTS",
    EprvLieu: "TROYES",
    InfoSupVal: "Débutants",
    Mandat: false
  },
  {
    EprvId: 2025038,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2024-11-16",
    EprvDateFin: "2024-11-17",
    StructureNom: "TROYES",
    EprvNom: "TIR SALLE",
    EprvLieu: "TROYES",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025039,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2024-11-16",
    EprvDateFin: "2024-11-17",
    StructureNom: "REIMS",
    EprvNom: "2X18M DE REIMS",
    EprvLieu: "REIMS",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025040,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-16",
    EprvDateFin: "2024-11-17",
    StructureNom: "HAGONDANGE",
    EprvNom: "TIR SALLE",
    EprvLieu: "HAGONDANGE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025041,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-16",
    EprvDateFin: "2024-11-17",
    StructureNom: "ST DIE",
    EprvNom: "CONCOURS DE TIR À L'ARC EN SALLE",
    EprvLieu: "ST DIE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025042,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-16",
    EprvDateFin: "2024-11-17",
    StructureNom: "TROYES",
    EprvNom: "TIR SALLE",
    EprvLieu: "TROYES",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025043,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-16",
    EprvDateFin: "2024-11-17",
    StructureNom: "REIMS",
    EprvNom: "2X18M DE REIMS",
    EprvLieu: "REIMS",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025044,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-16",
    EprvDateFin: "2024-11-17",
    StructureNom: "MONT L'ETROIT",
    EprvNom: "CONCOURS SALLE",
    EprvLieu: "COLOMBEY LES BELLES",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025045,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-17",
    EprvDateFin: "2024-11-17",
    StructureNom: "LINGOLSHEIM",
    EprvNom: "CHALLENGE D'AUTOMNE",
    EprvLieu: "LINGOLSHEIM",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025046,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-17",
    EprvDateFin: "2024-11-17",
    StructureNom: "STE CROIX ARC CLUB",
    EprvNom: "CONCOURS 2 X 18 M",
    EprvLieu: "STE CROIX EN PLAINE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025047,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2024-11-23",
    EprvDateFin: "2024-11-24",
    StructureNom: "FISMES",
    EprvNom: "CONCOURS SALLE 18 M",
    EprvLieu: "GYMNASE THIBAUT DE CHAMPAGNE",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025048,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-23",
    EprvDateFin: "2024-11-24",
    StructureNom: "DOMBASLE SUR MEURTHE",
    EprvNom: "CONCOURS SALLE SAISON 2024-2025",
    EprvLieu: "DOMBASLE SUR MEURTHE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025049,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-23",
    EprvDateFin: "2024-11-24",
    StructureNom: "SARREGUEMINES",
    EprvNom: "CONCOURS 18 M",
    EprvLieu: "SARREGUEMINES ",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025050,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-23",
    EprvDateFin: "2024-11-24",
    StructureNom: "BAR SUR SEINE",
    EprvNom: "LA JEAN VILAR",
    EprvLieu: "BAR SUR SEINE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025051,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-23",
    EprvDateFin: "2024-11-24",
    StructureNom: "FISMES",
    EprvNom: "CONCOURS SALLE 18 M",
    EprvLieu: "GYMNASE THIBAUT DE CHAMPAGNE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025052,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2024-11-24",
    EprvDateFin: "2024-11-24",
    StructureNom: "MULHOUSE CAB",
    EprvNom: "COMPÉTITION DES TROIS FRONTIÈRES",
    EprvLieu: "CENTRE SPORTIF RÉGIONAL D'ALSACE",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025053,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-24",
    EprvDateFin: "2024-11-24",
    StructureNom: "CD MEUSE",
    EprvNom: "TIR 2X18M BAR LE DUC",
    EprvLieu: "LIGNY EN BARROIS",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025054,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-24",
    EprvDateFin: "2024-11-24",
    StructureNom: "MULHOUSE CAB",
    EprvNom: "COMPÉTITION DES TROIS FRONTIÈRES",
    EprvLieu: "CENTRE SPORTIF RÉGIONAL D'ALSACE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025055,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2024-11-30",
    EprvDateFin: "2024-12-01",
    StructureNom: "GUEUX",
    EprvNom: "TIR EN SALLE GUEUX",
    EprvLieu: "GUEUX",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025056,
    ChampionnatId: 5,
    ChampionnatLibelle: "Rencontres Clubs Loisirs",
    EprvDateDebut: "2024-11-30",
    EprvDateFin: "2024-11-30",
    StructureNom: "SELESTAT",
    EprvNom: "SPECIAL JEUNES ET ADULTES DEBUTANTS",
    EprvLieu: "SELESTAT",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025057,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-30",
    EprvDateFin: "2024-12-01",
    StructureNom: "MAIZIERES GDE PAROISSE",
    EprvNom: "TIR SALLE",
    EprvLieu: "ROMILLY SUR SEINE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025058,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-30",
    EprvDateFin: "2024-12-01",
    StructureNom: "GUEUX",
    EprvNom: "TIR EN SALLE GUEUX",
    EprvLieu: "GUEUX",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025059,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-11-30",
    EprvDateFin: "2024-11-30",
    StructureNom: "HAYANGE",
    EprvNom: "SALLE 2X25M",
    EprvLieu: "HAYANGE",
    InfoSupVal: "2X25M",
    Mandat: false
  },
  {
    EprvId: 2025060,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-01",
    EprvDateFin: "2024-12-01",
    StructureNom: "HAYANGE",
    EprvNom: "SALLE 2X18M 2 DÉPARTS",
    EprvLieu: "HAYANGE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025061,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-01",
    EprvDateFin: "2024-12-01",
    StructureNom: "SELESTAT",
    EprvNom: "CONCOURS SELECTIF",
    EprvLieu: "SELESTAT",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025062,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2024-12-02",
    EprvDateFin: "2024-12-02",
    StructureNom: "HEILLECOURT",
    EprvNom: "TIR EN SALLE",
    EprvLieu: "FLAVIGNY SUR MOSELLE",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025063,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2024-12-02",
    EprvDateFin: "2024-12-02",
    StructureNom: "HEILLECOURT",
    EprvNom: "TIR EN SALLE",
    EprvLieu: "FLAVIGNY SUR MOSELLE",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025064,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-02",
    EprvDateFin: "2024-12-02",
    StructureNom: "HEILLECOURT",
    EprvNom: "TIR EN SALLE",
    EprvLieu: "FLAVIGNY SUR MOSELLE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025065,
    ChampionnatId: 2,
    ChampionnatLibelle: "Jeunes",
    EprvDateDebut: "2024-12-07",
    EprvDateFin: "2024-12-07",
    StructureNom: "CHARLEVILLE MEZIERES",
    EprvNom: "CONCOURS TELETHON",
    EprvLieu: "CHARLEVILLE-MÉZIÈRES",
    InfoSupVal: "RENCONTRE JEUNES",
    Mandat: false
  },
  {
    EprvId: 2025066,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2024-12-07",
    EprvDateFin: "2024-12-08",
    StructureNom: "MONTIGNY LES METZ A.C.",
    EprvNom: "TIR SALLE MONTIGNY",
    EprvLieu: "MONTIGNY-LÈS-METZ",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025067,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-07",
    EprvDateFin: "2024-12-08",
    StructureNom: "CONFLANS EN JARNISY",
    EprvNom: "CONCOURS CONFLANS EN JARNISY",
    EprvLieu: "CONFLANS EN JARNISY",
    InfoSupVal: "2X25M + 2X18M",
    Mandat: false
  },
  {
    EprvId: 2025068,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-07",
    EprvDateFin: "2024-12-08",
    StructureNom: "MONTIGNY LES METZ A.C.",
    EprvNom: "TIR SALLE MONTIGNY",
    EprvLieu: "MONTIGNY-LÈS-METZ",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025069,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-07",
    EprvDateFin: "2024-12-08",
    StructureNom: "VAUCHONVILLIERS",
    EprvNom: "CONCOURS SALLE",
    EprvLieu: "COSEC VENDEUVRE SUR BARSE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025070,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-07",
    EprvDateFin: "2024-12-08",
    StructureNom: "BETTANCOURT LA FERREE",
    EprvNom: "CONCOURS SALLE 2X18 BETTANCOURT-LA-FERRÉE",
    EprvLieu: "BETTANCOURT-LA-FERRÉE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025071,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2024-12-08",
    EprvDateFin: "2024-12-08",
    StructureNom: "MULHOUSE STA",
    EprvNom: "CHALLENGE US",
    EprvLieu: "MULHOUSE",
    InfoSupVal: "Challenge par équipe",
    Mandat: false
  },
  {
    EprvId: 2025072,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-08",
    EprvDateFin: "2024-12-08",
    StructureNom: "VANDOEUVRE",
    EprvNom: "41ÈME CHALLENGE DE VANDOEUVRE",
    EprvLieu: "VANDOEUVRE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025073,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-08",
    EprvDateFin: "2024-12-08",
    StructureNom: "VITTEL",
    EprvNom: "CONCOURS SALLE",
    EprvLieu: "VITTEL",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025074,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2024-12-14",
    EprvDateFin: "2024-12-14",
    StructureNom: "ST NICOLAS DE PORT",
    EprvNom: "LES ARCHERS PORTOIS",
    EprvLieu: "ST NICOLAS DE PORT 54210",
    InfoSupVal: "Débutants",
    Mandat: false
  },
  {
    EprvId: 2025075,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2024-12-14",
    EprvDateFin: "2024-12-14",
    StructureNom: "OBERNAI",
    EprvNom: "TROPHÉE DES LUTINS",
    EprvLieu: "OBERNAI",
    InfoSupVal: "Débutants",
    Mandat: false
  },
  {
    EprvId: 2025076,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2024-12-14",
    EprvDateFin: "2024-12-15",
    StructureNom: "EPERNAY",
    EprvNom: "CONCOURS SELECTIF SALLE",
    EprvLieu: "EPERNAY",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025077,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-14",
    EprvDateFin: "2024-12-15",
    StructureNom: "MARLY POURNOY",
    EprvNom: "CD57 JEUNES",
    EprvLieu: "MARLY",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025078,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-14",
    EprvDateFin: "2024-12-15",
    StructureNom: "VILLE SOUS LA FERTE",
    EprvNom: "TIR SALLE 2025",
    EprvLieu: "BAR SUR AUBE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025079,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-14",
    EprvDateFin: "2024-12-15",
    StructureNom: "EPERNAY",
    EprvNom: "CONCOURS SELECTIF SALLE",
    EprvLieu: "EPERNAY",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025080,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2024-12-15",
    EprvDateFin: "2024-12-15",
    StructureNom: "OBERNAI",
    EprvNom: "CONCOURS DE NOEL",
    EprvLieu: "OBERNAI",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025081,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-15",
    EprvDateFin: "2024-12-15",
    StructureNom: "ST NICOLAS DE PORT",
    EprvNom: "LES ARCHERS PORTOIS",
    EprvLieu: "ST NICOLAS DE PORT 54210",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025082,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-15",
    EprvDateFin: "2024-12-15",
    StructureNom: "OBERNAI",
    EprvNom: "CONCOURS DE NOEL",
    EprvLieu: "OBERNAI",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025083,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2024-12-22",
    EprvDateFin: "2024-12-22",
    StructureNom: "CR DU GRAND EST",
    EprvNom: "SALLE GRJ MULHOUSE",
    EprvLieu: "MULHOUSE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025084,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2025-01-04",
    EprvDateFin: "2025-01-04",
    StructureNom: "VARANGEVILLE",
    EprvNom: "CONCOURS AMICAL DEBUTANTS",
    EprvLieu: "VARANGEVILLE ",
    InfoSupVal: "Débutants",
    Mandat: false
  },
  {
    EprvId: 2025085,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2025-01-04",
    EprvDateFin: "2025-01-05",
    StructureNom: "ARCIS SUR AUBE",
    EprvNom: "CONCOURS SALLE ",
    EprvLieu: "ARCIS SUR AUBE",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025086,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-04",
    EprvDateFin: "2025-01-05",
    StructureNom: "ARS SUR MOSELLE",
    EprvNom: "TIR À 18M",
    EprvLieu: "ARS SUR MOSELLE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025087,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-04",
    EprvDateFin: "2025-01-05",
    StructureNom: "ARCIS SUR AUBE",
    EprvNom: "CONCOURS SALLE ",
    EprvLieu: "ARCIS SUR AUBE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025088,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-04",
    EprvDateFin: "2025-01-05",
    StructureNom: "STE MENEHOULD",
    EprvNom: "CONCOURS SALLE",
    EprvLieu: "SAINTE MENEHOULD",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025089,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-04",
    EprvDateFin: "2025-01-04",
    StructureNom: "VARANGEVILLE",
    EprvNom: "CONCOURS SPECIALE FEMMES",
    EprvLieu: "VARANGEVILLE  ",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025090,
    ChampionnatId: 10,
    ChampionnatLibelle: "Tournoi Poussin",
    EprvDateDebut: "2025-01-04",
    EprvDateFin: "2025-01-04",
    StructureNom: "VARANGEVILLE",
    EprvNom: "CONCOURS SPECIAL U11 ET U13",
    EprvLieu: "VARANGEVILLE ",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025091,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-05",
    EprvDateFin: "2025-01-05",
    StructureNom: "VARANGEVILLE",
    EprvNom: "CONCOURS ANNUEL 2025",
    EprvLieu: "VARANGEVILLE  ",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025092,
    ChampionnatId: 1,
    ChampionnatLibelle: "Divers",
    EprvDateDebut: "2025-01-11",
    EprvDateFin: "2025-01-11",
    StructureNom: "HAUCOURT-MOULAINE",
    EprvNom: "25M SALLE RUE DE LA GARONNE",
    EprvLieu: "HAUCOURT ST CHARLES",
    InfoSupVal: "Challenge Individuel",
    Mandat: false
  },
  {
    EprvId: 2025093,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2025-01-11",
    EprvDateFin: "2025-01-11",
    StructureNom: "HAUCOURT-MOULAINE",
    EprvNom: "AMICAL 18 M SALLE HAUCOURT MOULAINE",
    EprvLieu: "HAUCOURT ST CHARLES",
    InfoSupVal: "Débutants",
    Mandat: false
  },
  {
    EprvId: 2025094,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2025-01-11",
    EprvDateFin: "2025-01-11",
    StructureNom: "REIMS",
    EprvNom: "CONCOURS JEUNES DE REIMS",
    EprvLieu: "REIMS",
    InfoSupVal: "Jeunes débutants",
    Mandat: false
  },
  {
    EprvId: 2025095,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2025-01-11",
    EprvDateFin: "2025-01-11",
    StructureNom: "LINGOLSHEIM",
    EprvNom: "SPÉCIAL JEUNES DECOUVERTE ADULTES",
    EprvLieu: "LINGOLSHEIM",
    InfoSupVal: "Débutants",
    Mandat: false
  },
  {
    EprvId: 2025096,
    ChampionnatId: 5,
    ChampionnatLibelle: "Rencontres Clubs Loisirs",
    EprvDateDebut: "2025-01-11",
    EprvDateFin: "2025-01-11",
    StructureNom: "COLMAR",
    EprvNom: "TOURNOI DÉBUTANTS JEUNES ET ADULTES",
    EprvLieu: "COLMAR",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025097,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-11",
    EprvDateFin: "2025-01-12",
    StructureNom: "THAON LES VOSGES",
    EprvNom: "TIR À 18M",
    EprvLieu: "THAON LES VOSGES",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025098,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-11",
    EprvDateFin: "2025-01-12",
    StructureNom: "CHARLEVILLE MEZIERES",
    EprvNom: "CHALLENGE DE LA NOUVELLE ANNÉE",
    EprvLieu: "CHARLEVILLE-MÉZIÈRES",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025099,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-11",
    EprvDateFin: "2025-01-12",
    StructureNom: "COLMAR",
    EprvNom: "TIR EN SALLE 18M",
    EprvLieu: "COLMAR",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025100,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-12",
    EprvDateFin: "2025-01-13",
    StructureNom: "EINVILLE",
    EprvNom: "CONCOURS SALLE 2025 EINVILLE",
    EprvLieu: "EINVILLE",
    InfoSupVal: "4X18M",
    Mandat: false
  },
  {
    EprvId: 2025101,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-12",
    EprvDateFin: "2025-01-12",
    StructureNom: "HAUCOURT-MOULAINE",
    EprvNom: "18 M OFFICIEL SALLE DE SPORTS",
    EprvLieu: "HAUCOURT ST CHARLES",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025102,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-12",
    EprvDateFin: "2025-01-12",
    StructureNom: "LINGOLSHEIM",
    EprvNom: "CONCOURS INDIVIDUEL",
    EprvLieu: "LINGOLSHEIM",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025103,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2025-01-13",
    EprvDateFin: "2025-01-13",
    StructureNom: "HEILLECOURT",
    EprvNom: "TIR EN SALLE",
    EprvLieu: "FLAVIGNY SUR MOSELLE",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025104,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-13",
    EprvDateFin: "2025-01-13",
    StructureNom: "HEILLECOURT",
    EprvNom: "TIR EN SALLE",
    EprvLieu: "FLAVIGNY SUR MOSELLE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025105,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2025-01-18",
    EprvDateFin: "2025-01-18",
    StructureNom: "BRUMATH",
    EprvNom: "SPÉCIAL JEUNES ET ADULTES DEBUTANTS",
    EprvLieu: "BRUMATH",
    InfoSupVal: "Débutants",
    Mandat: false
  },
  {
    EprvId: 2025106,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2025-01-18",
    EprvDateFin: "2025-01-19",
    StructureNom: "FISMES",
    EprvNom: "CHAMPIONNAT DE RONDE SALLE",
    EprvLieu: "FISMES",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025107,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2025-01-18",
    EprvDateFin: "2025-01-18",
    StructureNom: "HEILLECOURT",
    EprvNom: "TIR EN SALLE - CR PARA-TIR",
    EprvLieu: "FLAVIGNY SUR MOSELLE",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025108,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-18",
    EprvDateFin: "2025-01-19",
    StructureNom: "CD AUBE",
    EprvNom: "CHAMPIONNAT AUBE SALLE",
    EprvLieu: "CHAOURCE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025109,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-18",
    EprvDateFin: "2025-01-19",
    StructureNom: "NEUFCHATEAU",
    EprvNom: "CONCOURS SALLE SUPPORT CD 88",
    EprvLieu: "NEUFCHATEAU",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025110,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-18",
    EprvDateFin: "2025-01-19",
    StructureNom: "SUIPPES",
    EprvNom: "SUIPPES SALLE 2025",
    EprvLieu: "SUIPPES",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025111,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-18",
    EprvDateFin: "2025-01-19",
    StructureNom: "FISMES",
    EprvNom: "CHAMPIONNAT DE RONDE SALLE",
    EprvLieu: "FISMES",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025112,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-18",
    EprvDateFin: "2025-01-18",
    StructureNom: "HEILLECOURT",
    EprvNom: "TIR EN SALLE - CR PARA-TIR",
    EprvLieu: "FLAVIGNY SUR MOSELLE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025113,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-19",
    EprvDateFin: "2025-01-19",
    StructureNom: "BRUMATH",
    EprvNom: "COMPETITION SALLE 2X18M",
    EprvLieu: "BRUMATH",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025114,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-19",
    EprvDateFin: "2025-01-19",
    StructureNom: "VOLGELSHEIM",
    EprvNom: "CHAMPIONNAT HAUT-RHIN SALLE",
    EprvLieu: "VOLGELSHEIM",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025115,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-25",
    EprvDateFin: "2025-01-26",
    StructureNom: "CD ARDENNES",
    EprvNom: "CD SALLE",
    EprvLieu: "SEDAN",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025116,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-25",
    EprvDateFin: "2025-01-26",
    StructureNom: "CD MARNE",
    EprvNom: "CD 51",
    EprvLieu: "CHALONS EN CHAMPAGNE",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025117,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-25",
    EprvDateFin: "2025-01-26",
    StructureNom: "TOUL",
    EprvNom: "CONCOURS SALLE TOUL CD ADULTES",
    EprvLieu: "TOUL",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025118,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-25",
    EprvDateFin: "2025-01-26",
    StructureNom: "FAREBERSVILLER",
    EprvNom: "CD 57",
    EprvLieu: "FARÉBERSVILLER ",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025119,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-25",
    EprvDateFin: "2025-01-25",
    StructureNom: "TOUL",
    EprvNom: "CONCOURS SALLE TOUL CD JEUNES",
    EprvLieu: "TOUL",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025120,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-01-26",
    EprvDateFin: "2025-01-26",
    StructureNom: "WISSEMBOURG",
    EprvNom: "CHAMPIONNAT DEPARTEMENTAL 67 SALLE",
    EprvLieu: "WISSEMBOURG",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025121,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-02-01",
    EprvDateFin: "2025-02-02",
    StructureNom: "CD AUBE",
    EprvNom: "CHAMPIONNAT RÉGIONNAL GRAND EST",
    EprvLieu: "SAINT JULIEN",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025122,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2025-02-08",
    EprvDateFin: "2025-02-09",
    StructureNom: "PONT A MOUSSON",
    EprvNom: "T€AM'S 2025",
    EprvLieu: "PONT-AMOUSSON",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025123,
    ChampionnatId: 5,
    ChampionnatLibelle: "Rencontres Clubs Loisirs",
    EprvDateDebut: "2025-02-08",
    EprvDateFin: "2025-02-08",
    StructureNom: "MAREUIL SUR AY",
    EprvNom: "LOISIRS JEUNES",
    EprvLieu: "AY CHAMPAGNE",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025124,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-02-08",
    EprvDateFin: "2025-02-09",
    StructureNom: "PONT A MOUSSON",
    EprvNom: "T€AM'S 2025",
    EprvLieu: "PONT-AMOUSSON",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025125,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-02-08",
    EprvDateFin: "2025-02-09",
    StructureNom: "COLMAR",
    EprvNom: "TOURNOI SCRATCH 18M AVEC FINALES",
    EprvLieu: "COLMAR",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025126,
    ChampionnatId: 4,
    ChampionnatLibelle: "Para-tir à l'arc à 18m",
    EprvDateDebut: "2025-02-15",
    EprvDateFin: "2025-02-16",
    StructureNom: "PONT A MOUSSON",
    EprvNom: "T€AM'S DOUBLE MIXTE",
    EprvLieu: "PONT-À-MOUSSON",
    InfoSupVal: "",
    Mandat: false
  },
  {
    EprvId: 2025127,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-02-15",
    EprvDateFin: "2025-02-16",
    StructureNom: "PONT A MOUSSON",
    EprvNom: "T€AM'S DOUBLE MIXTE",
    EprvLieu: "PONT-À-MOUSSON",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025128,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-02-16",
    EprvDateFin: "2025-02-16",
    StructureNom: "BRUNSTATT",
    EprvNom: "CONCOURS SELECTIF ",
    EprvLieu: "BRUNSTATT",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025129,
    ChampionnatId: 7,
    ChampionnatLibelle: "Tir à 18m",
    EprvDateDebut: "2025-02-23",
    EprvDateFin: "2025-02-23",
    StructureNom: "LINGOLSHEIM",
    EprvNom: "COMPÉTITION SALLE 2X18M + FINALES",
    EprvLieu: "LINGOLSHEIM",
    InfoSupVal: "2X18M",
    Mandat: false
  },
  {
    EprvId: 2025130,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2025-03-01",
    EprvDateFin: "2025-03-02",
    StructureNom: "MONT L'ETROIT",
    EprvNom: "CONCOURS AMICAL",
    EprvLieu: "COLOMBEY LES BELLES",
    InfoSupVal: "Tir à l Arc au Féminin",
    Mandat: false
  },
  {
    EprvId: 2025131,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2025-03-02",
    EprvDateFin: "2025-03-02",
    StructureNom: "MULHOUSE STA",
    EprvNom: "TROPHÉE DES MIXTES DEPARTEMENTAL",
    EprvLieu: "MULHOUSE",
    InfoSupVal: "Trophée des mixtes",
    Mandat: false
  },
  {
    EprvId: 2025132,
    ChampionnatId: 2,
    ChampionnatLibelle: "Jeunes",
    EprvDateDebut: "2025-03-08",
    EprvDateFin: "2025-03-08",
    StructureNom: "CHALONS EN CHAMPAGNE 1ER",
    EprvNom: "CONCOURS JEUNES",
    EprvLieu: "CHÂLONS EN CHAMPAGNE",
    InfoSupVal: "RENCONTRE JEUNES",
    Mandat: false
  },
  {
    EprvId: 2025133,
    ChampionnatId: 3,
    ChampionnatLibelle: "Loisirs",
    EprvDateDebut: "2025-03-22",
    EprvDateFin: "2025-03-22",
    StructureNom: "OBERNAI",
    EprvNom: "TROPHEE DU PRINTEMPS",
    EprvLieu: "OBERNAI",
    InfoSupVal: "Débutants",
    Mandat: false
  },
  {
    EprvId: 2025134,
    ChampionnatId: 6,
    ChampionnatLibelle: "Tir 3D",
    EprvDateDebut: "2025-03-30",
    EprvDateFin: "2025-03-30",
    StructureNom: "CHAOURCE",
    EprvNom: "CONCOURS 3D CHAOURCE",
    EprvLieu: "LES LOGES MARGUERONS",
    InfoSupVal: "1 X 24 CIBLES",
    Mandat: false
  }
]

export const championnats: Championnat[] = (function initChampionnats(): Championnat[] {
  const result: Championnat[] = []
  const ids: number[] = []

  data.forEach(row => {
    if (ids.includes(row.ChampionnatId)) {
      return
    }

    ids.push(row.ChampionnatId)
    result.push({
      id: row.ChampionnatId,
      name: row.ChampionnatLibelle,
    })
  })

  return result
})()

export default data
